import React from "react";
import ContactForms from "../Components/ContactForms";
import Footer from "../Components/Footer";
import HeroSection from "../Components/HeroSection";
import Navbar from "../Components/Navbar";

function Contact() {
  return (
    <div>
      <Navbar />
      <HeroSection contact={true} changeBg={true} />
      <ContactForms />
      <Footer />
    </div>
  );
}

export default Contact;
