import React, { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";
import Pic from "../gallery/poster.jpg";
import "../App.css";
import { useNavigate } from "react-router-dom";
const Poster = () => {
  const [showPoster, setShowPoster] = useLocalStorage("showPoster", true);
  const navigate = useNavigate();
  const NavigateUser = () => {
    navigate("/school");
  };

  return (
    <>
      {showPoster && (
        <div
          className="poster-container bottom-0 fixed right-0 cursor-pointer"
          onClick={NavigateUser}
        >
          <div className="poster-content p-4">
            <img
              src={`${Pic}`}
              alt="Poster"
              className="relative w-full h-96 object-cover object-center rounded-lg "
            />
            <button
              className="absolute top-0 right-0 poster-toggle-btn bg-black text-white py-2 px-4 rounded-full hover:bg-red-600"
              onClick={() => setShowPoster(!showPoster)}
            >
              x
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Poster;
