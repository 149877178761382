import React from "react";
import { BrowserRouter as Routing, Routes, Route } from "react-router-dom";
import App from "./App";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Pricing from "./Pages/Pricing";
import School from "./Pages/School";
import "./App.css";
import { motion, useScroll } from "framer-motion";
import Poster from "./Components/Poster";
import Apply from "./Pages/Apply";
import Courses from "./Pages/Courses";
function Router() {
  const { scrollYProgress } = useScroll();
  return (
    <>
      <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress, zIndex: 2 }}
      />
      <Routing>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/apply" element={<Apply />} />
          <Route path="/about" element={<About />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/school" element={<School />} />
        </Routes>
        <Poster />
      </Routing>
    </>
  );
}

export default Router;
