export const data = [
  {
    id: 1,
    price: "15,000",
    category: "copper",
    info: [
      "upto 2 page intergration",
      "social media intergration",
      "5 day delivery",
      "Responsive website",
      "Free quality image uploading",
      "Free SSL certificate",
    ],
  },
  {
    id: 2,
    price: "30,000",
    category: "Gold",
    info: [
      "Elegant Design And Appearance",
      "Improved functionality",
      "upto 10 pages",
      "CMS dashboard",
      "10 working days delivery time",
      "24/7 customer support",
    ],
  },
  {
    id: 3,
    price: "50,000",
    category: "platinum",
    info: [
      "Advanced functionality",
      "High end design and styling",
      "Unlimited web pages",
      "Ecommerce",
      "Enhanced contact forms",
      "Online payments",
      "Free SSL certificate",
      "24/7 customer support",
      "Realtime updates enables",
    ],
  },
];
