import React from "react";
import Design from "../gallery/Design2.svg";
import Cloud from "../gallery/cloud hosting.svg";
import Graphic from "../gallery/graphic design.svg";
import SEO from "../gallery/SEO.svg";
function Services() {
  return (
    <div id="services" className="mx-40 md:mx-0">
      {" "}
      <h1 className="text-center text-3xl my-4 capitalize">Our Services</h1>
      <div className=" mx-4">
        <div className=" grid grid-cols-2 gap-3 md:flex flex-col">
          {/* box */}
          <a href="#design">
            <div className="h-48 bg-[#f4f4f4] flex justify-center place-items-center flex-col rounded border border-2 border-[#eeb90a] ">
              <img src={Design} className="h-16" alt="" srcset="" />
              <h1 className="text-center">
                website Design and App development
              </h1>
            </div>
          </a>

          <a href="#hosting">
            <div className="h-48 bg-[#f4f4f4] flex justify-center place-items-center flex-col rounded border border-1 border-[#000]">
              <img src={Cloud} className="h-16" alt="" srcset="" />
              <h1>Web hosting </h1>
            </div>
          </a>
          <a href="#graphic">
            <div className="h-48 bg-[#f4f4f4] flex justify-center place-items-center flex-col rounded border border-1 border-[#000]">
              <img src={Graphic} className="h-16" alt="" srcset="" />
              <h1>Graphic design</h1>
            </div>
          </a>

          <a href="#seo">
            <div className="h-48 bg-[#f4f4f4] flex justify-center place-items-center flex-col rounded border border-2 border-[#eeb90a]">
              <img src={SEO} className="h-16" alt="" srcset="" />
              <h1>SEO & SEM</h1>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Services;
