import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./nav.css";
function Navbar() {
  const [toggle, setToggle] = useState(false);
  const [classN, setClassN] = useState("");
  const [btnActive, setBtnActive] = useState("");

  const onToggle = () => {
    setClassN("show_list");
    setBtnActive("active");
    if (classN === "show_list") {
      setClassN("");
    }
    if (btnActive === "active") {
      setBtnActive("");
    }
    setToggle(!toggle);
  };
  return (
    <div className="z-50">
      <nav class="nav z-50">
        <div class="container">
          <div class="logo">
            <Link to="/">
              JotexCode <span className="text-[#ebac0f]">D</span>
              ynamics
            </Link>
          </div>
          <div class={`main_list ${classN}`} id="mainListDiv">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                {window.location.pathname !== "/" ? (
                  <Link to="/">services</Link>
                ) : (
                  <a href="#services">services</a>
                )}
              </li>

              <li>
                <Link to="/pricing">pricing</Link>
              </li>

              <li>
                <Link to="/about">About us</Link>
              </li>
              <li>
                <Link to="/courses">Courses</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          <div class="media_button">
            <button
              onClick={onToggle}
              class={`main_media_button ${btnActive}`}
              id="mediaButton"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
