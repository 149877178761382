import React from "react";
function Promotion() {
  return (
    <div className="mx-40 bg-gradient-to-r to-[#111827] from-[#ebbf31] h-60 mx-40 flex justify-center items-center space-y-4 flex-col md:h-50 w-50 md:mx-0">
      <h1 className="text-4xl md:text-2xl text-white md:text-center">
        Enjoy Upto <span>25%</span> Discount{" "}
      </h1>

      <h3 className="text-2xl text-white">Hurry up!</h3>
      <p className="text-xl text-white">Lets build it for you</p>
    </div>
  );
}

export default Promotion;
