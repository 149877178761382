import React from "react";
import { data } from "../utils/PricingData";
import Price from "./Price";

function PriciningGrid() {
  return (
    <div
      className="grid grid-cols-3 mx-40 space-x-4 md:flex flex-col md:space-y-4 md:mx-10"
      style={{ marginBottom: "70px" }}
    >
      {data.map((d) => (
        <Price data={d} />
      ))}
    </div>
  );
}

export default PriciningGrid;
