import React from "react";
import ReduxJpg from "../thumbnails/Redux.png";
const CourseComponent = () => {
  const courses = [
    {
      title: "Building an Ecommerce Cart with Redux Toolkit and React",
      platform: "Lively Jose Code",
      image:
        "https://cdn.devdojo.com/images/may2021/Red%20Orange%20Abstract%20Modern%20Shapes%20General%20Twitch%20Banner.jpg",
      description:
        "Learn all about React Hooks and how they revolutionize the way we write React components.",
    },
    {
      title: "Advanced React Techniques",
      platform: "Lively Jose Code",
      image:
        "https://cdn.devdojo.com/images/may2021/Red%20Orange%20Abstract%20Modern%20Shapes%20General%20Twitch%20Banner.jpg",
      description:
        "Learn all about React Hooks and how they revolutionize the way we write React components.",
    },
  ];

  const youtubeTutorials = [
    {
      title: "Redux Toolkit Tutorial: Build an Ecommerce Cart",
      description:
        "In this tutorial, we will be building a fully functional ecommerce cart using React and Redux Toolkit. We will start by setting up our development environment and creating the basic structure of our application. Then, we will integrate Redux Toolkit into our project and use it to manage the state of our cart. We will cover topics such as adding products to the cart, changing the quantity of products in the cart, and removing items from the cart. By the end of this tutorial, you will have a solid understanding of how to build a scalable ecommerce cart using React and Redux Toolkit. You will also have the skills to extend this project and add new features such as user authentication.",
      image: ReduxJpg,
      platform: "Lively Jose Code",
      link: "https://youtu.be/2RbdHAXqbTg",
    },
  ];

  return (
    <div className="bg-gray-100 py-8 border  ">
      <div className=" mx-4">
        <h2 className="text-2xl font-bold mt-8 mb-4 mx-3">YouTube Tutorials</h2>
        <div className="grid grid-cols-2  xl:grid xl:grid-cols-1 xl:space-x-0">
          {youtubeTutorials.map((tutorial, index) => (
            <div
              key={index}
              className="border bg-white p-4 shadow-md rounded-lg flex mb-2 flex-col mx-2 "
            >
              <img
                src={tutorial.image}
                alt={tutorial.title}
                className="xl:w-full xl:h-36 w-1/2 h-auto   object-cover rounded-lg mr-4"
              />
              <div>
                <h3 className="text-xl font-semibold mb-2">{tutorial.title}</h3>
                <p className="text-gray-600">{tutorial.description}</p>

                <p className=" mt-2 text-[red]">
                  Link:{" "}
                  <a href={tutorial.link} target="__blank">
                    view on youtube
                  </a>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CourseComponent;
