import React from "react";
import Image from "../gallery/joseime.jpg";
function AboutSection() {
  return (
    <>
      <div className="mx-7 my-8">
        <p className="text-center my-2 text-[#dd780c] text-xl">
          For more Than 2 years we've been helping businesses develop their
          presence online
        </p>
        <p className="text-center text-xl my-3 md:text-[17px]">
          We work with small to large scale businesses in making sure demands in
          the business ecosystem are delivered by offering software based
          solutions which help the businesses become more sustainable and
          reliable to its clients.
        </p>
        <div className="flex flex-col-reverse">
          <div className="flex justify-center flex-col items-center space-y-2">
            <img
              src={Image}
              alt="founder"
              srcset=""
              className="rounded h-72 w-64"
            />
            <h3>Joseph Muchene</h3>
            <h4>Founder</h4>
            <p className="md:text-center">Full Stack Software Developer</p>
          </div>
          <div>
            <div>
              <h1 className="capitalize text-center my-4 text-3xl md:text-xl">
                Meet The Founder At WebGuru Solutions
              </h1>
            </div>

            <hr className="m-2" />
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutSection;
