import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import { Link } from "react-router-dom";
import HeroSection from "../Components/HeroSection";
import Footer from "../Components/Footer";

function Apply() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Code to handle form submission goes here
  };

  return (
    <>
      <div>
        <Navbar />
      </div>
      <div>
        <HeroSection apply={true} changeBg={true} />
      </div>
      <div className="relative">
        <div className="flex">
          <img
            className="w-1/2 mt-10 h-screen rounded-md md:hidden"
            src="https://ubunifucollege.com/assets/images/classes/apply2.jpg"
            alt="Form Image"
          />
          <form
            className="w-2/3 p-6 flex justify-center flex-col md:w-full "
            onSubmit={handleSubmit}
          >
            <h2 className="text-lg font-medium mb-4 text-center">Apply Now</h2>
            <label htmlFor="name" className="block mb-2">
              Name:
            </label>
            <input
              id="name"
              className="rounded border border-gray-400 p-2 w-full"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <label htmlFor="email" className="block mb-2">
              Email:
            </label>
            <input
              id="email"
              className="border border-gray-400 p-2 w-full"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="phone" className="block mb-2">
              Phone:
            </label>
            <input
              id="phone"
              className="border border-gray-400 p-2 w-full"
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            <button className="bg-black text-white p-2 mt-4">Submit</button>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Apply;
