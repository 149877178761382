import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../gallery/Elevate.png";

function Footer() {
  const [path, setPath] = useState(window.location.pathname);
  const [links, setLinks] = useState([
    {
      link: "Web & App Development",
      goto: "design",
    },
    {
      link: "Web Hosting Services",
      goto: "hosting",
    },
    {
      link: "Graphic design",
      goto: "design",
    },
    {
      link: "SEO & SEM",
      goto: "seo",
    },
  ]);
  return (
    <div className="relative border-t-8 shadow-md">
      <footer className="p-4  sm:p-6 bg-[#f4f4f4] mt-4">
        <div className="flex justify-between md:flex-col">
          <div className="mb-6 md:mb-0 ml-10 md:ml-4">
            <a href="#" className="flex items-center md:flex-col">
              <span className="self-center font-semibold whitespace-nowrap md:mb-4">
                JotexCode Dynamics
              </span>
            </a>
          </div>
          <div className="grid   gap-6 grid-cols-3 md:flex justify-center flex-col">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">
                Navigation
              </h2>
              <ul className="text-gray-600 ">
                <li className="mb-4">
                  <Link to="/" className="hover:underline">
                    Home
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/about" className="hover:underline">
                    About
                  </Link>
                </li>

                <li className="mb-4">
                  <Link to="/contact" className="hover:underline">
                    Contact
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/school" className="hover:underline">
                    School
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">
                Follow us
              </h2>
              <ul className="text-gray-600 ">
                <li className="mb-4">
                  <Link to="" className="hover:underline ">
                    Facebook
                  </Link>
                </li>
                <li>
                  <Link to="" className="hover:underline">
                    Twitter
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">
                Our Services
              </h2>
              <ul className="text-gray-600 ">
                {links &&
                  links.map((link) => (
                    <li className="mb-4">
                      {path === "/" ? (
                        <a href={`#${link.goto}`} className="hover:underline">
                          {link.link}
                        </a>
                      ) : (
                        <>
                          <Link to="/" className="hover:underline">
                            {link.link}
                          </Link>
                        </>
                      )}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto  lg:my-8" />
        <div className="flex  justify-center">
          <span className="text-sm text-gray-600 text-center ">
            © {new Date().getFullYear()}{" "}
            <Link to="/" className="hover:underline">
              JotexCode Dynamics
            </Link>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
