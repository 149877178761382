import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import Delivery from "../gallery/delivery.png";
import Azim from "../gallery/Azim.png";
import portfolio from "../gallery/portfolio.png";
import CommingSoon from "../gallery/commingSoon.jpeg";
import "./styles.css";

// import required modules
import { Pagination } from "swiper";

function Portfolio() {
  return (
    <>
      <h1 className="text-center text-3xl z-40">Our Portfolio </h1>
      <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
        <SwiperSlide className="rounded">
          <img src={Delivery} alt="" srcset="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Azim} alt="" srcset="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={portfolio} alt="" srcset="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={CommingSoon} alt="" srcset="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={CommingSoon} alt="" srcset="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={CommingSoon} alt="" srcset="" />
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default Portfolio;
