import React from "react";

function Price({ data }) {
  return (
    <div className="shadow-md mt-4  border  border-[#dbad06] p-2 rounded md:h-auto ">
      {/* top price tag */}
      <div className="mb-4 flex justify-between border-b-2 border-gray-700">
        <div>{data.category}</div>
        <div>Ksh {data.price}</div>
      </div>

      <div>
        <ul className="flex flex-col space-y-3 ">
          {data.info.map((i) => (
            <li className="py-2 border-b-2 border-dotted border-[#000]">{i}</li>
          ))}
          {/* button proceed */}
          <button class=" my-3 bg-[#000] hover:bg-[#b58306] text-white  py-2 px-6 ">
            Proceed
          </button>
        </ul>
      </div>
    </div>
  );
}

export default Price;
