import CourseComponent from "../Components/CourseComponent";
import HeroSection from "../Components/HeroSection";
import Navbar from "../Components/Navbar";

export default function Courses() {
  return (
    <div>
      <Navbar />
      <div>
        <HeroSection changeBg={true} courses={true} />
        <CourseComponent />
      </div>
    </div>
  );
}
