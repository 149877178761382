import "./App.css";
import Navbar from "./Components/Navbar";
import HeroSection from "./Components/HeroSection";
import Services from "./Components/Services";
// import Elevate from "./Components/DesignProcess";
import Product from "./Components/Product";
import WhyElevated from "./Components/WhyElevated";
import Portfolio from "./Components/Portfolio";
import Promotion from "./Components/Promotion";
import Footer from "./Components/Footer";
import "./App.css";
import { motion, useScroll } from "framer-motion";
import Poster from "./Components/Poster";

function App() {
  const { scrollYProgress } = useScroll();
  return (
    <>
      <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress }}
      />

      <Navbar />
      <HeroSection home={true} />
      <Services />
      <WhyElevated />
      <Product
        title="Website Design Services"
        goto="design"
        desc="Truly effective website design is more than just page layouts and
            brand colors. From URLs and indexing to H1s and conversion funnels,
            our web development process is complete and comprehensive. Let us
            sweat the details so you can reap the rewards."
      />

      <Portfolio />

      <Product
        reverse={true}
        title="Graphic Design"
        goto="graphic"
        desc="Graphic design will breathe life into your marketing efforts and advertising campaigns. It is a form of communication that will draw in a potential customer and inform, delight, and persuade him to convert. It is also used to provide a visual identity to your content, and help improve your brand recognition. Whether you want to have a stellar business card, some great images for your social media, an infographic for your blog, professional design will make your marketing "
      />
      <Promotion />

      <Product
        reverse={false}
        goto="hosting"
        title="Web hosting and Management"
        desc="Reliable and high-performing web hosting is a crucial part of any successful website. When you’re thinking about building a new website, your choice of host is probably one of the last things on your mind. But, neglecting to choose the right host can have disastrous effects on your website—and your business. At Elevate web solutions we help you rank higher on Google and Bing for example. Fast loading sites rank better. They also convert better. One benefit of having SEO"
      />

      <Footer />
    </>
  );
}

export default App;
