import React from "react";

function ContactForms() {
  return (
    <>
      <div className="flex justify-center my-5">
        <h1 className="text-3xl md:text-xl md:text-center">
          Let's Start a Conversation
        </h1>
      </div>
      <div className="grid grid-cols-2 mx-40 md:mx-10 md:flex md:flex-col md:space-y-4">
        {/* Get in touch */}
        <div className="space-y-4 ">
          <h1 className="text-2xl">Get in Touch</h1>

          <div className="flex flex-col space-y-3">
            <h1>Call Us</h1>
            <p>(254) 759 689-541</p>
          </div>
          <div className="flex flex-col space-y-3">
            <h1>Email Us</h1>
            <p className="md:text-xs">ngugimuchene@gmail.com</p>
          </div>
        </div>

        <div>
          <h1 className="text-2xl mb-3">Ask A question</h1>
          <form className="">
            <div className="flex flex-col my-2">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" className="py-2 rounded mx-1" />
            </div>
            <div className="flex flex-col my-2">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" className="py-2 rounded mx-1" />
            </div>
            <div className="flex flex-col my-2">
              <label htmlFor="phone">Phone Number</label>
              <input type="text" name="phone" className="py-2 rounded mx-1" />
            </div>

            <div className="flex flex-col my-2">
              <select name="service" id="service" className="py-2 rounded mx-1">
                <option value="" selected>
                  I am looking For
                </option>
                <option value="" selected>
                  Website Design
                </option>
                <option value="" selected>
                  SEO
                </option>
                <option value="" selected>
                  Wesite Hosting
                </option>
                <option value="" selected>
                  other
                </option>
              </select>
            </div>

            <div className="flex flex-col my-2">
              <label htmlFor="help">How can we Help</label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="5"
                className="py-2 rounded mx-1 "
              ></textarea>
            </div>

            <div className="flex justify-center">
              <button class=" my-3 bg-[#000] hover:bg-[#b58306] text-white  py-2 px-6 ">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ContactForms;
