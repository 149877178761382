import React from "react";

function Course({ data }) {
  console.log(data);
  return (
    <div className="shadow-md px-9 py-9 rounded cursor-pointer hover:bg-[#111829] hover:text-white transition mx-2">
      <div>
        <h1 className="text-center text-[#fbc30b] rounded">{data.id}</h1>
      </div>
      <h1 className="my-4 text-xl">{data.title}</h1>
      <ol className="space-y-4  ">
        {data.info.map((i, x) => (
          <li key={i.id}>{i}</li>
        ))}
      </ol>
    </div>
  );
}

export default Course;
