export const curriculum = [
  {
    id: "1",
    title: "Javascript programming fundamentals",
    info: [
      "Fundamentals of Javascript Code",
      "Advanced functions (scope & closures)",
      "Asynchronous Javascript (callback methodology)",
      "Feartures in the latest versions of ECMA",
      "modularity (import and export)",
    ],
  },
  {
    id: "2",
    title: "React Development Bootcamp",
    info: [
      "Learn how to write your own React code to build dynamic web mobile ",
      "Understand how to intergrate Api's using Axios to build webhooks",
      "Create mobile applications and upload them to the IOS and Android app store",
    ],
  },
  {
    id: "3",
    title: "Javascript Development with Node.js",
    info: [
      "The runtime environment for node js",
      "Creating a basic server to accept and respond to web traffic",
      "Programming with the node file system to serve and manipulate static assets",
    ],
  },
  {
    id: "4",
    title: "NOSQL databases with mongoDB",
    info: [
      "How to setup mongodb locally",
      " working with Mongodb compass GUI",
      " Creating database schemas",
      " Creating documents ",
      "Creating Search algorithms",
    ],
  },
  {
    id: "5",
    title: "Javascript Development with Express.js",
    info: [
      "Creating API route",
      "Parsing and creating middleware",
      "Hosting Express.js Routes on a Node Server",
      " Analyzing the diffrent routes (POST,GET,PUT,DELETE)",
    ],
  },
  {
    id: "6",
    title: "Web Development industry and Portfolio",
    info: ["Portfolio creation",],
  },
];
