import React from "react";
import AboutSection from "../Components/AboutSection";
import Footer from "../Components/Footer";
import HeroSection from "../Components/HeroSection";
import Navbar from "../Components/Navbar";

function About() {
  return (
    <div>
      <Navbar />
      <HeroSection about={true} changeBg={true} home={false} />
      <AboutSection />
      <Footer />
    </div>
  );
}

export default About;
