import React from "react";
import Course from "../Components/Course";
import HeroSection from "../Components/HeroSection";
import Navbar from "../Components/Navbar";
import { curriculum } from "../utils/courseData";
import Footer from "../Components/Footer";
function School() {
  return (
    <div>
      <Navbar />
      <HeroSection school={true} changeBg={true} />
      <div className="flex justify-center">
        <p className="w-1/2 text-center text-[#ec8714] mt-3 flex justify-center items-center md:mx-4 md:w-auto">
          Become a Javascipt developer with this immersive Certificate Program.
          Learn object oriented programming OOP, create API'S and learn popular
          frameworks and libraries to build functional and responsive websites
        </p>
      </div>

      <div className="grid grid-cols-3 mx-40 space-y-6 md:flex md:flex-col md:mx-0">
        {curriculum.map((m, v) => (
          <Course data={m} key={v.id} />
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default School;
