import React from "react";
import { useNavigate } from "react-router-dom";
import DesignLarge from "../gallery/DesignLarge.svg";
function Product({ title, desc, goto,reverse }) {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center flex-col items-center mx-3 my-4">
      <h1 className="text-3xl my-2 md:text-xl text-center capitalize" id={goto}>
        {title}
      </h1>

      <div className="grid items-center grid-cols-2  md:flex flex-col space-y-4 mx-10">
        <>
          <div>
            <img src={DesignLarge} alt="" srcset="" className="w-96 " />
          </div>
          <div>
            <p className="text-center">{desc}</p>

            <div className="flex justify-end">
              <button
                onClick={() => navigate("/contact")}
                class=" my-3 bg-[#000] hover:bg-[#b58306] text-white  py-2 px-6 "
              >
                get started
              </button>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}

export default Product;
