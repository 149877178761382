import Navbar from "../Components/Navbar";
import React from "react";
import HeroSection from "../Components/HeroSection";
import Footer from "../Components/Footer";
import PriciningGrid from "../Components/PriciningGrid";

function Pricing() {
  return (
    <div>
      <Navbar />
      <HeroSection changeBg={true} pricing={true} home={false} />
      <PriciningGrid />
      <Footer />
    </div>
  );
}

export default Pricing;
