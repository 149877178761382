import React from "react";
import Pic from "../gallery/pc.jpg";
import { useNavigate } from "react-router-dom";

function HeroSection({
  changeBg,
  pricing,
  home,
  about,
  courses,
  blog,
  contact,
  school,
  apply,
}) {
  const navigate = useNavigate("/");
  return (
    <div
      style={
        !changeBg
          ? {
              backgroundImage: `url(${Pic})`,
            }
          : { background: `#111827` }
      }
      className=" w-full  h-96  bg-cover bg-center bg-no-repeat flex justify-center items-center flex-col space-y-4 sm:w-screen md:text-xl md:h-72 "
    >
      {home && (
        <>
          <h1 className="capitalize text-4xl text-white md:text-xl md:text-center md:mt-5 ">
            Web design and Digital marketing agency
          </h1>
          <h3 className="text-2xl md:text-[17px] md:text-center text-[#d5a907]">
            Designing unique web Experiences
          </h3>
        </>
      )}

      {pricing && (
        <>
          <h1 className="text-5xl capitalize text-white md:text-xl md:text-center ">
            Our Pricing
          </h1>
          <h3 className="text-white text-2xl md:text-[17px] md:text-center">
            Website Design plans and pricing
          </h3>
        </>
      )}
      {about && (
        <h1 className="text-5xl capitalize text-white md:text-xl md:text-center ">
          About us
        </h1>
      )}
      {blog && (
        <h1 className="text-5xl capitalize text-white md:text-xl md:text-center ">
          Our Blog
        </h1>
      )}
      {contact && (
        <h1 className="text-5xl capitalize text-white md:text-xl md:text-center ">
          contact us
        </h1>
      )}
      {school && (
        <>
          <h1 className="text-5xl capitalize text-white md:text-xl md:text-center ">
            JotexCode Dynamics School
          </h1>
          <h3 className="text-white text-2xl md:text-[17px] md:text-center">
            Javascript development Certificate
          </h3>

          <p>
            <button
              type="button"
              onClick={() => navigate("/apply")}
              class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-full text-sm px-16 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
            >
              Apply Now!
            </button>
          </p>
        </>
      )}
      {apply && (
        <h1 className="capitalize text-4xl text-white md:text-xl md:text-center md:mt-5 ">
          Become a web developer in 5 weeks
        </h1>
      )}
      {courses && (
        <>
          <h1 className="capitalize text-4xl text-white md:text-xl md:text-center md:mt-5 ">
            check out Our courses
          </h1>
          <p>
            <button
              type="button"
              onClick={() => navigate("/school")}
              class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-full text-sm px-16 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
            >
              View our curriculum
            </button>
          </p>
        </>
      )}
    </div>
  );
}

export default HeroSection;
