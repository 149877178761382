import React from "react";

function WhyElevated() {
  return (
    <div>
      <h1 className="text-center my-3 text-3xl">Why Choose Us</h1>

      <div className="flex mx-3 justify-center md:mx-0">
        <div className="flex space-x-4 flex-row items-center md:flex-col md:space-y-3 md:space-x-0">
          {/* box */}

          <div className="h-64 bg-[#f4f4f4] w-60 p-3 rounded  border border-1 border-[#000]">
            <h1 className="text-center"> Your success is our success</h1>
            <hr />
            <p className="my-2 text-center">
              We don’t only ask, but we listen to every bit of information and
              focus on your deepest concerns.
            </p>
          </div>
          <div className="h-64 bg-[#f4f4f4] w-60 p-3 rounded  border border-2 border-[#eeb90a]">
            <h1 className="text-center"> We’re professionals</h1>
            <hr />
            <p className="my-2 text-center">
              We’ve worked hard over the years and have gathered professional
              experience which puts us in the best position to serve you.
            </p>
          </div>
          <div className="h-64 bg-[#f4f4f4] w-60 p-3 rounded  border border-1 border-[#000]">
            <h1 className="text-center"> Stand out from the crowd</h1>
            <hr />
            <p className="my-2 text-center">
              JotexCode Dynamics specializes in making sure your
              Website is what you need it to be – the Online Version of your
              Business.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyElevated;
